import { tns } from "tiny-slider"

function setup(wrapper) {
    const container = wrapper.querySelector(".hero-slider")
    const controlsContainer = wrapper.querySelector(".hero-slider-controls")
    const navContainer = wrapper.querySelector(".hero-slider-navigation")

    tns({
        container,
        controlsContainer,
        navContainer,
        mouseDrag: true,
        items: 1,
    })
}

function init() {
    const wrapper = document.querySelector(".hero-slider-wrapper")

    if (!wrapper) return

    setup(wrapper)
}


window.addEventListener('load', function () {
    init()
})
