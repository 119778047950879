function resetFilter(e) {
    e.preventDefault()
    window.location.href = `${window.location.origin}${window.location.pathname}`
}

function init() {
    const reset = document.querySelector('.product-mobile-filter__actions [data-action="reset"]')

    if (!reset) return;
    reset.addEventListener('click', resetFilter)
}

window.addEventListener('load', init)
