function setup(select) {
    select.addEventListener('change', (e) => {
        let path = window.location.origin
        window.location = (e.target.value === 'View All Categories') ? `${path}/blog` : `${path}/${e.target.value}`
    })
}

function init() {

    const select = document.querySelector(".blog-page .titlebar__actionbar select")

    if (!select) return

    setup(select)
}

window.addEventListener('load', function () {
    init()
})
