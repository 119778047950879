window.addEventListener('load', function () {

    const MOBILE_MENU_ACTIVE_CLASS = 'mobile-menu--active' 
    const MOBILE_MENU_TEMPLATE_CLASS = 'mobile-menu__template' 

    const MOBILE_MENU = document.querySelector('.mobile-menu')
    const MOBILE_MENU_BACK = document.querySelector('.mobile-menu__back')
    const MOBILE_MENU_CONTENT = document.querySelector('.mobile-menu__content')
    const MOBILE_MENU_PRIMARY = document.querySelector('.mobile-menu__primary')
    const MOBILE_MENU_SECONDARY = document.querySelector('.mobile-menu__secondary')

    let state = [];

    // How does the menu work?
    function bindNavigationEvents(container) {

        // Get all direct child menu triggers 
        // This prevents binding events to templates that are cloned and bound later
        let nav = container.querySelector('.mobile-menu__navigation-content')

        if (container.classList.contains('mobile-menu__primary')) {
            nav = container.querySelector('.mobile-menu__navigation')
        }

        if (nav === null) {
            return
        }

        const childrenWithMenus = Array.from(nav.children).filter(child => {
            if (child.querySelector(`.${MOBILE_MENU_TEMPLATE_CLASS}`)) {
                return true
            }
        })

        childrenWithMenus.forEach(child => {
            child.addEventListener('click', () => {
                const template = child.querySelector(`.${MOBILE_MENU_TEMPLATE_CLASS}`)
                state = updateMenu(template.innerHTML, state)
                MOBILE_MENU.classList.add(MOBILE_MENU_ACTIVE_CLASS)
            })
        })
    }

    function updateMenu(HTML = '', history = []) {

        if (HTML === '') {
            MOBILE_MENU_SECONDARY.innerHTML = ''
            MOBILE_MENU.classList.remove(MOBILE_MENU_ACTIVE_CLASS)
            history = []
            return history
        }

        MOBILE_MENU_SECONDARY.innerHTML = HTML 
        history.push(HTML)
        bindNavigationEvents(MOBILE_MENU_SECONDARY)

        return history
    }

    function init() {
        bindNavigationEvents(MOBILE_MENU_PRIMARY)

        MOBILE_MENU_BACK.addEventListener('click', () => {

            if (history.length <= 1) {
                return updateMenu()
            }

            state.pop() // Remove the current menu we don't really need it anymore
            const previous = state.pop() // This gets the previous menu

            updateMenu(previous, state)
        })
    }

    init();
})
