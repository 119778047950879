
function hide(e) {
    e.target.classList.remove('hide')
    e.target.close()
    e.target.removeEventListener('webkitAnimationEnd', hide)
}

function setup(dialog) {

    const { id } = dialog

    const triggers = Array.from(document.querySelectorAll(`[data-dialog="${id}"]`))

    if (!triggers.length) {
        console.warn(`Dialog #${id} doesn't have any triggers.`)
    }

    // Open dialog
    triggers.forEach(trigger => {
        trigger.addEventListener('click', e => {
            e.preventDefault()

            // Ensure all other dialogs are closed
            // Array.from(document.querySelectorAll('dialog.form-dialog')).forEach(dialog => {
            //     dialog.close()
            // })

            if (!dialog.open) {
                dialog.show()
            } else {
                dialog.classList.add('hide')
                dialog.addEventListener('webkitAnimationEnd', hide, false)
            }
        })
    })

    // Clicking close button in dialog
    dialog.querySelector('.search-dialog__close').addEventListener('click', () => {
        dialog.classList.add('hide')
        dialog.addEventListener('webkitAnimationEnd', hide, false)
    })
}

function init() {

    const dialogs = Array.from(document.querySelectorAll(".search-dialog"))

    if (!dialogs.length) return

    dialogs.forEach(setup)
}


window.addEventListener('load', function () {
    init()
})
