import { tns } from "tiny-slider"

function setup(wrapper) {
    const container = wrapper.querySelector(".planner-guide__slider")
    const controlsContainer = wrapper.querySelector(".planner-guide__slider-controls")

    tns({
        container,
        controlsContainer,
        nav: false,
        gutter: 1
    })
}

function init() {
    const wrapper = document.querySelector(".planner-guide__slider-wrapper")

    if (!wrapper) return

    setup(wrapper)
}

window.addEventListener('load', function () {
    init()
})
