import Litepicker from 'litepicker';

const datepickers = Array.from(document.querySelectorAll('[data-datepicker]'))

function setup(element) {
    let picker = new Litepicker({
        element,
        format: 'DD / MM / YYYY'
    });
}

function init() {

    if(!datepickers.length) return;

    datepickers.forEach(setup)
}

init()
