import { debounce } from "lodash"
import { tns } from "tiny-slider"

function setup(wrapper) {
    const container = wrapper.querySelector(".product-slider-images")
    const controlsContainer = wrapper.querySelector(".product-slider-controls")
    let navContainer = wrapper.querySelector(".product-slider-thumbnails")

    if (!navContainer.children) {
        navContainer = false
    }

    const main = tns({
        container,
        controlsContainer,
        nav: false,
        navContainer,
        mouseDrag: true,
    })

    let displayIndex = main.getInfo().displayIndex
    const thumbnailControls = wrapper.querySelector(".product-slider-thumbnails-controls")

    const thumbs = tns({
        container: navContainer,
        nav: false,
        controlsContainer: thumbnailControls,
        items: 3,
        gutter: 10,
        responsive: {
            1024: {
                items: 4,
            },
            1480: {
                items: 5,
                gutter: 16,
            },
        },
    })

    window.productSlider = {
        main,
        thumbs
    }

    main.events.on("transitionEnd", () => {
        displayIndex = main.getInfo().displayIndex
        setActiveThumbnail(displayIndex)
    })

    function setActiveThumbnail(display) {
        Array.from(thumbs.getInfo().slideItems).forEach((item) => {
            item.classList.remove("tns-nav-active")
            if (parseInt(item.dataset.nav) === display) {
                item.classList.add("tns-nav-active")
            }
        })
    }

    function addThumbClickEvents() {
        Array.from(thumbs.getInfo().slideItems).forEach((item) => {
            item.addEventListener('click', () => {
                main.goTo(parseInt(item.dataset.nav) - 1)
            })
        })
    }

    // wait for iOS
    setTimeout(() => {
        positionThumbnailControlsVertically(wrapper)
    })

    window.addEventListener("resize", debounce(() => {
        positionThumbnailControlsVertically(wrapper)
    }, 300))

    setActiveThumbnail(displayIndex)
    addThumbClickEvents()
}

function positionThumbnailControlsVertically(wrapper) {
    const thumbnailWrapper = wrapper.querySelector(".product-slider-thumbnails-wrap")
    const thumbnailControls = wrapper.querySelector(".product-slider-thumbnails-controls")
    const { height } = thumbnailWrapper.getBoundingClientRect()

    thumbnailControls.style.setProperty('--top', `${height / 2}px`)
}

function init() {
    const wrapper = document.querySelector(".product-images")

    if (!wrapper) return

    setup(wrapper)
}


window.addEventListener('load', function () {
    init()
})
