function start(previous) {

    const current = document.documentElement.scrollTop || document.body.scrollTop
    const header = document.querySelector('.header')
    const height = parseInt(getComputedStyle(document.documentElement)
        .getPropertyValue('--header-height'));

    if (!previous) {
        previous = current
    }

    const down = !(previous > current)

    if (down && previous > height * 2) {
        header.classList.add('header--hidden')
    } else {
        header.classList.remove('header--hidden')
    }

    return current 

}

function init() {

    let pos = start()

    window.addEventListener('scroll', () => {
        pos = start(pos)
    })
}

window.addEventListener('load', init)
