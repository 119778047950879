(() => {

    let dialogs = null
    let activeDialog = null

    function hideDialogs() {

        dialogs.forEach(dialog => {
            dialog.close()
        })
    }

    function setup(dialog) {
        const {id} = dialog
        const SIDEBAR_TRIGGER_ACTIVE_CLASS = 'mega-dialog-sidebar__link--active'
        const SIDEBAR_MENU_ACTIVE_CLASS = 'mega-dialog-sidebar__menu--active'
        const triggers = Array.from(document.querySelectorAll(`[data-dialog="${id}"]`))
        const sidebarTriggers = Array.from(dialog.querySelectorAll(`.mega-dialog-sidebar__link`))
        const sidebarMenus = Array.from(dialog.querySelectorAll(`.mega-dialog-main > div`))
        const close = dialog.querySelector('.mega-dialog__close')

        if (!triggers.length) {
            console.warn(`Dialog ${id} doesn't have any triggers`)
            return
        }

        let initialTimer = null
        let timer = null

        triggers.forEach(trigger => {

            trigger.addEventListener('mouseenter', () => {
                clearTimeout(timer)
                hideDialogs()

                initialTimer = setTimeout(() => {
                    dialog.show()
                    activeDialog = dialog
                }, 500)
            })

            trigger.addEventListener('mouseleave', () => {
                clearTimeout(initialTimer)

                timer = setTimeout(() => {
                    dialog.close()
                }, 500)
                console.log(`leave ${id}`)
            })
        })

        dialog.addEventListener('mouseenter', () => {
            clearTimeout(timer)
            console.log('freeze timer')
        })

        dialog.addEventListener('mouseleave', () => {
            timer = setTimeout(() => {
                dialog.close()
            }, 500)
            console.log(`leave ${id}`)
        })

        if (sidebarTriggers) {
            sidebarTriggers.forEach(trigger => {
                const id = trigger.dataset.menu
                const menu = document.querySelector(`.mega-dialog-main [data-menu="${id}"]`)

                if (menu) {
                    trigger.addEventListener('mouseenter', () => {

                        sidebarTriggers.forEach(trigger => trigger.classList.remove(SIDEBAR_TRIGGER_ACTIVE_CLASS))
                        trigger.classList.add(SIDEBAR_TRIGGER_ACTIVE_CLASS)
                        sidebarMenus.forEach(menu => menu.classList.remove(SIDEBAR_MENU_ACTIVE_CLASS))
                        menu.classList.add(SIDEBAR_MENU_ACTIVE_CLASS)
                    })
                }
            })
        }

        document.addEventListener('keydown', (event) => {
            if (event.code === 'Escape') {
                dialog.close()
            }
        }, false)

        close.addEventListener('click', (event) => {
            event.preventDefault()
            dialog.close()
        })
    }

    function init() {
        dialogs = Array.from(document.querySelectorAll('.mega-dialog'))

        if (!dialogs.length) return

        dialogs.forEach(setup)
    }

    window.addEventListener('load', function () {
        init()
    })
})()
