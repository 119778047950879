import { tns } from "tiny-slider"

function setup(wrapper) {
    const container = wrapper.querySelector(".products-slider-container")
    const controlsContainer = wrapper.querySelector(".products-slider-controls")

    tns({
        container,
        controlsContainer,
        nav: false,
        items: 1,
        gutter: 16,
        mouseDrag: true,
        responsive: {
            480: {
                items: 2,
            },
            768: {
                items: 3,
                gutter: 24
            },
            1024: {
                items: 4,
            }
        }
    })
}

function init() {
    const wrapper = document.querySelector(".products-slider-section")

    if (!wrapper) return

    setup(wrapper)
}


window.addEventListener('load', function () {
    init()
})
