/*
* A simple utility to lock scroll position and return to the current position on unlock
*/

const root = document.documentElement
let scrollTop = 0

window.addEventListener('scroll', () => {
    scrollTop = document.body.scrollTop
})

export function scrollLock() {
    root.style.setProperty('--scroll-top', `${-scrollTop}px`)
    document.body.classList.add('noscroll')
}

export function scrollUnlock() {
    scrollTop = parseInt(getComputedStyle(root).getPropertyValue('--scroll-top')) * -1
    document.body.classList.remove('noscroll')
    window.scrollTo(0, scrollTop)
}

