function init() {
    const socialShare = document.querySelector(".js-share");
    if (socialShare) {
        const shareLinks = socialShare.querySelectorAll(".social-link");
        shareLinks.forEach((link) => {
            link.addEventListener("click", (e) => {
                e.preventDefault();

                const origEl = e.target || e.srcElement;
                const url = origEl.href;

                const width = 575;
                const height = 400;

                const left =
                    document.documentElement.clientWidth / 2 - width / 2;
                const top =
                    (document.documentElement.clientHeight - height) / 2;

                const opts = `${
                    "status=1,resizable=yes" + ",width="
                }${width},height=${height},top=${top},left=${left}`;

                const win = window.open(url, "", opts);
                win.focus();
            });
        });
    }

    const link = document.querySelector(".social-url");

    if (link) {
        link.addEventListener("click", (e) => {
            e.preventDefault();
            copyTextToClipboard(link.getAttribute("href"));
        });
    }


    function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand("copy");
            var msg = successful ? "successful" : "unsuccessful";
            if (successful) {
                showSuccessMessage();
            }
            console.log("Fallback: Copying text command was " + msg);
        } catch (err) {
            console.error("Fallback: Oops, unable to copy", err);
        }

        document.body.removeChild(textArea);
    }

    function copyTextToClipboard(text) {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(
            function () {
                console.log("Async: Copying to clipboard was successful!");
                showSuccessMessage();
            },
            function (err) {
                console.error("Async: Could not copy text: ", err);
            },
        );
    }

    function showSuccessMessage() {
        link.classList.add("social-url--copied");
        setTimeout(() => {
            link.classList.remove("social-url--copied");
        }, 1000);
    }
}

window.addEventListener('load', function () {
    init()
})
