import Litepicker from 'litepicker'

window.addEventListener('load', function () {
    const fields = Array.from(document.querySelectorAll(".repeating-datetime-field"))

    function cloneField(el, container) {
        const template = el.querySelector('.repeating-datetime-container')
        const clone = template.cloneNode(true)

        // Make sure names are unique so when we submit we get all of the data
        Array.from(clone.querySelectorAll('[name]')).forEach(field => {
            const name = field.getAttribute('name')
            field.value = '' // Unset the value
            field.setAttribute('name', `${name}-${Date.now()}`)
        })

        el.insertBefore(clone, el.children[0])

        setupContainerEvents(el, clone)

        return clone
    }

    function setupContainerEvents(el, container) {
        let clone = undefined
        const ADD_BUTTON = container.querySelector('.repeating-datetime__add')
        const REMOVE_BUTTON = container.querySelector('.repeating-datetime__remove')

        new Litepicker({
            element: container.querySelector('[data-datepicker]'),
            format: 'DD / MM / YYYY'
        })

        ADD_BUTTON.addEventListener('click', e => {
            e.preventDefault()

            clone = cloneField(el, container)
        })

        REMOVE_BUTTON.addEventListener('click', e => {
            e.preventDefault()

            // Only remove the container if we successfully cloned
            if (clone) {
                container.remove()
            }
        })
    }

    function setup(el) {
        const containers = el.querySelectorAll('.repeating-datetime-container')

        Array.from(containers).forEach(container => {
            setupContainerEvents(el, container)
        })
    }

    function init() {
        if (!fields.length) return
        fields.forEach(setup)
    }

    init()
})
