import { scrollLock, scrollUnlock } from '../helpers/scroll-lock.js'

function setup(dialog) {

    const { id } = dialog

    const triggers = Array.from(document.querySelectorAll(`[data-dialog="${id}"]`))

    if (!triggers.length) {
        console.warn(`Dialog #${id} doesn't have any triggers.`)
    } 

    // Open dialog 
    triggers.forEach(trigger => {
        trigger.addEventListener('click', e => {
            e.preventDefault()

            // Ensure all other dialogs are closed
            Array.from(document.querySelectorAll('dialog.form-dialog')).forEach(dialog => {
                if (dialog.open) {
                    dialog.close()
                    scrollUnlock()
                }
            })

            dialog.showModal()
            scrollLock()
        })
    })

    // Clicking outside dialog
    dialog.addEventListener('click', e => {
        if(e.target.nodeName === 'DIALOG') {
            dialog.close()
            scrollUnlock()
        }
    })
    
    // Clicking close button in dialog
    dialog.querySelector('.form-dialog__close').addEventListener('click', () => {
        dialog.close()
        scrollUnlock()
    })
}

function init() {

    const dialogs = Array.from(document.querySelectorAll(".form-dialog"))

    if (!dialogs.length) return

    dialogs.forEach(setup)
}

init()
