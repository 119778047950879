// @TODO
// Update this list
// - search
//
// - pin interactions
//
// - In region choose store type?
//
// - Already store type and choose region?
//
// - Searching while choose store type
//
// scrolling search area
// wrap address on store locations
// type pins should remove regions with no stores of that type
// when in a region and selecting store type only show store type of that region with a message if there are none
// - going back shows all regions with that type
// search is contextual based on region and going back redoes search for all regions
// search effects pins
// clicking pin opents region with store selected
// Searching for a region name should show showrooms in that region
// Clicking search button should do nothing
// Search icon should be X when searching

import initMaps from "../vendor/google-maps.js"
import { Fzf } from '../vendor/fzf.js'
import getIconPath from "../helpers/getIconPath.js";
import debounce from "../helpers/debounce.js";
import parseLatLng from "../helpers/parseLatLng.js";

window.addEventListener('load', function () {

    const wrapper = document.querySelector(".finder-showrooms")

    if (!wrapper) return

    const iconpath = getIconPath()

    const mapSettings = {
        zoom: 6,
        center: { lat: -40.8583946, lng: 171.7685522 },
        regionZoom: 8,
        mapTypeControl: false,
    }

    const activeTypes = [] // All active types set

    // Elements for access later
    const finder = document.querySelector(".finder")
    const typeElements = Array.from(document.querySelectorAll(".finder-tag"))
    const regionInputs = Array.from(document.querySelectorAll('.finder-sidebar input[type="checkbox"]'))
    const regionElements = Array.from(document.querySelectorAll(".finder-region"))
    const storeElements = Array.from(document.querySelectorAll(".finder-store"))
    const searchForm = document.querySelector(".finder .search-form")

    const input = searchForm.querySelector('input')
    const globalResultsEl = document.querySelector('.finder-results-list')
    const globalSearchElements = storeElements.map(getDataFromStoreElement)

    const FzfSettings = {
        selector: (item) => `${item.title} ${item.address} ${item.region}`
    }

    const regionalFzf = regionElements.map(regionElement => {
        const el = regionElement // We want the actual element to add a class to it
        const countEl = regionElement.querySelector('.finder-region__title-indicator .n')
        const originalCount = countEl.cloneNode(true).innerText // make sure this is not a refrence
        const resultsEl = regionElement.querySelector('.finder-region__results-list')
        const title = regionElement.querySelector('.finder-region__title').innerText
        const storeElements = Array.from(regionElement.querySelectorAll('.finder-store'))
        const storeSearchData = storeElements.map(getDataFromStoreElement)
        const fzf = new Fzf(storeSearchData, FzfSettings)

        return {
            el,
            title,
            fzf,
            resultsEl,
            countEl,
            originalCount
        }
    })

    // const FINDER = {
    //     _activeRegion: null,
    //     get activeRegion() {
    //         return this._activeRegion
    //     },
    //     set activeRegion(value) {
    //         this._activeRegion = value
    //         console.log('this is a side effect')
    //     }
    // }

    // const r = Array.from(document.querySelectorAll('.finder-sidebar input[type="checkbox"]')).map(input => {
    //     const el = document.querySelector(`[for="${input.id}"]`).parentElement
    //     const title = el.querySelector('.finder-region__title').innerText
    //     const originalCount = el.querySelector('n').innerText
    // })

    let canvases = []

    let activeRegion = null // Which region are we looking at
    let Google = null // Access Google maps APIs

    function getDataFromStoreElement(el) {
        // Probably should do some sanity checks

        console.log(el.querySelector('.finder-store__phone'))

        return {
            el: el.cloneNode(true),
            title: el.querySelector('.finder-store__title') ? el.querySelector('.finder-store__title').innerText : null,
            region: el.dataset.region,
            address: el.querySelector('.finder-store__address') ? el.querySelector('.finder-store__address').innerText : null,
            phone: el.querySelector('.finder-store__phone') ? el.querySelector('.finder-store__phone').innerText : null,
            email: el.querySelector('.finder-store__mail') ? el.querySelector('.finder-store__mail').innerText : null,
            type: el.dataset.type
        }
    }

    function clearRegionalLists() {
        regionalFzf.forEach(data => {
            data.el.classList.remove('finder-region--searching')
            data.countEl.innerText = data.originalCount
            data.resultsEl.innerHTML = ''
        })
    }

    function clearGlobalList() {
        globalResultsEl.innerHTML = ''
    }

    function clearResultsLists() {
        clearGlobalList()
        clearRegionalLists()
        finder.classList.remove('finder-results--empty')
        Array.from(document.querySelectorAll('.finder-results--none')).forEach(item => {
            item.classList.remove('finder-results--none')
        })
    }

    function search() {

        if (input.value === '') {
            clearResultsLists()
            showMarkers()
            return
        }

        let resultsEl = globalResultsEl
        let fzf = new Fzf(globalSearchElements, FzfSettings)

        // If we are in a region swap the fuzzy finder from global to regional
        const regionalData = regionalFzf.find(region => region.title === activeRegion)

        clearResultsLists()

        if (regionalData) {
            fzf = regionalData.fzf
            resultsEl = regionalData.resultsEl
            regionalData.el.classList.add('finder-region--searching')
        }

        let entries = fzf.find(input.value)

        const tags = Array.from(document.querySelectorAll('.finder-tag'))
            .filter(tag => tag.classList.contains('finder-tag--active'))

        // Only filter if one of the two tags is selected
        if (tags.length === 1) {
            entries = entries.filter(entry => entry.item.type === tags[0].dataset.type)
        }

        if (regionalData) {
            regionalData.countEl.innerText = entries.length
        }

        if (entries.length === 0 && input.value) {
            resultsEl.parentElement.classList.add('finder-results--none')
            finder.classList.add('finder-results--empty')
        } else {
            resultsEl.parentElement.classList.remove('finder-results--none')
            finder.classList.remove('finder-results--empty')
        }

        entries.map(entry => {
            resultsEl.appendChild(entry.item.el)
        })

        showMarkers()
    }

    function setupSearch() {

        if (!searchForm) {
            console.warn('search will not work without a form')
            return
        }

        input.addEventListener('keyup', debounce(() => {
            search()
        }))
    }

    /**
     * When our state changes we want to make changes to the map and markers
     * this is where we bind those events.
     */
    function bindDomEvents() {

        if (regionInputs.length < 0) {
            throw new Exception("This application will not work without checkboxes.")
        }

        // Manage types
        typeElements.forEach((typeElement) => {
            const { type } = typeElement.dataset

            typeElement.addEventListener("click", () => {
                // DOM updates
                typeElement.classList.toggle("finder-tag--active")
                finder.classList.toggle("tag--active")

                // DATA tracking
                const index = activeTypes.indexOf(type)

                if (index > -1) {
                    activeTypes.splice(index, 1)
                } else {
                    activeTypes.push(type)
                }

                // This is a horrible workaround :(
                if (input.value === '' && activeTypes.length) {
                    input.value = ' '
                }

                search()

                if (input.value === ' ') {
                    input.value = ''
                }
            })
        })

        // Manage regions
        regionInputs.forEach((input) => {
            input.addEventListener("change", (e) => {

                // This makes checkboxes like radio buttons - only one on at a time but we can toggle them ( unlike radios )
                regionInputs.forEach((ip) => {
                    if (ip !== input) {
                        ip.checked = false
                    }
                })

                if (!input.checked) {
                    canvases.forEach(canvas => {
                        let { map } = canvas
                        map.setZoom(mapSettings.zoom)
                        map.panTo(mapSettings.center)
                    })
                    activeRegion = null
                    finder.classList.remove('finder--region-active')
                    search()
                    return
                }

                regionElements.every((regionElement) => {
                    if (regionElement.querySelector("label").getAttribute("for") === input.id) {
                        const region = regionElement.querySelector(".finder-region__title").innerText
                        activeRegion = region
                        finder.classList.add('finder--region-active')
                        showMarkers()
                        canvases.forEach(canvas => {
                            let { map } = canvas
                            map.setZoom(mapSettings.regionZoom)
                            map.panTo(parseLatLng(input.dataset.latlng))
                        })
                        return false
                    }
                    return true
                })

                search()
            })
        })
    }

    function getActiveRegionElement() {
        if (activeRegion === null) {
            console.warn('no activeRegion set')
            return false
        }
        return getRegionByTitle(activeRegion)
    }

    function getRegionByTitle(title = null) {
        if (title === null) {
            console.warn('title is required')
            return false
        }
        return regionElements.find(element => element.querySelector('.finder-region__title').innerText === title)
    }

    function getRegionSearchResultElements(regionElement) {
        return Array.from(regionElement.querySelectorAll('.finder-region__results-list .finder-store'))
    }

    function getGlobalSearchResultElements() {
        return Array.from(globalResultsEl.querySelectorAll('.finder-store'))
    }

    function getSearchResultElements() {
        if (!activeRegion) {
            return getGlobalSearchResultElements()
        }
        return getRegionSearchResultElements(getActiveRegionElement())
    }

    function getStoreIdsFromElements(list) {
        return list.map(el => el.dataset.uid)
    }

    /**
     * Show and hide markers depending on settings
     * @param region: string - must be a valid region
     * @param region: Array - set of marker types "t", "s" or "o"
     * @example showMarkers() - This will show all 'o' markers, used on country level
     * @example showMarkers("Auckland") - This will show all markers on the "Auckland" region
     * @example showMarkers(null, ['t']) - This will show all 't' markers
     */
    function showMarkers(region = null, types = []) {

        if (region === null) region = activeRegion
        if (types.length === 0) types = activeTypes

        let storeIds = getStoreIdsFromElements(getSearchResultElements())

        canvases.forEach(canvas => {

            let { map } = canvas

            canvas.markers.forEach((marker) => {
                marker.setMap(null)

                if (!region && marker.type === "o" && types.length === 0) {
                    marker.setMap(map)
                }

                if (!region && types.length > 0 && types.indexOf(marker.type) > -1) {
                    marker.setMap(map)
                }

                if (region && marker.type !== "o" && marker.region === region && types.length === 0) {
                    marker.setMap(map)
                }

                if (
                    region &&
                    marker.type !== "o" &&
                    marker.region === region &&
                    types.length > 0 &&
                    types.indexOf(marker.type) > -1
                ) {
                    marker.setMap(map)
                }

                // If we are searching and the marker isn't one of the results hide the marker
                if (input.value && storeIds.indexOf(marker.id) === -1) {
                    marker.setMap(null)
                }
            })
        })
    }

    /**
     * Create markers based on stores.
     * Each store will have two markers, one for the country view and one for the region view.
     * Store data is pulled from the HTML.
     * Stores are required to be inside a region so we use that as the base element.
     */
    function createMarkers() {

        if (!regionElements || !storeElements) {
            console.error("The elements required for creating markers don't exist.")
            return
        }

        regionElements.forEach((regionElement) => {

            const regionTitle = regionElement.querySelector(".finder-region__title").innerText

            if (!regionTitle) {
                console.warn("Regions must have a valid title.")
            }

            const stores = Array.from(regionElement.querySelectorAll(".finder-store"))

            if (!stores.length) {
                console.warn("There are no stores in this region")
                return
            }

            const infoWindow = new google.maps.InfoWindow();

            stores.forEach((storeElement) => {

                if (!storeElement.dataset.latlng) {
                    console.warn("Can't create a marker without lat lng data.")
                    return
                }

                const position = parseLatLng(storeElement.dataset.latlng)
                const type = storeElement.dataset.type
                const scaledSize = new google.maps.Size(40, 40)

                canvases.forEach(canvas => {
                    let { map } = canvas

                    const m1 = new Google.Marker({
                        map,
                        id: storeElement.dataset.uid,
                        region: regionTitle,
                        position,
                        type: "o",
                        icon: {
                            url: `${iconpath}pin-o.svg`,
                            scaledSize,
                        },
                    })

                    const m2 = new Google.Marker({
                        map,
                        id: storeElement.dataset.uid,
                        region: regionTitle,
                        position,
                        type,
                        icon: {
                            url: `${iconpath}pin-${type}.svg`,
                            scaledSize,
                        },
                    })

                    m1.addListener('click', () => {
                        infoWindow.setContent(storeElement.outerHTML)
                        infoWindow.open(map, m1)
                    })

                    m2.addListener('click', () => {
                        infoWindow.setContent(storeElement.outerHTML)
                        infoWindow.open(map, m2)
                    })


                    m1.setMap(null)
                    m2.setMap(null)

                    canvas.markers.push(m1)
                    canvas.markers.push(m2)
                })
            })
        })
    }

    /**
     * Load Google maps and expose APIs
     * We create one map per canvas and store them in an array maps for later
     * One map is used on mobile and one is used on desktop
     */
    async function loadGoogleMaps() {

        initMaps({
            key: "AIzaSyDbwP2WUsq-wEizQLcAtkgS6n_dGqKtxB8", // This should come from and .env file :(
            v: "weekly",
        })

        const { Map } = await google.maps.importLibrary("maps")
        const { Marker } = await google.maps.importLibrary("marker")

        const els = Array.from(document.querySelectorAll(".map-canvas"))

        if (!els.length) throw new Exception("Google maps can't work without a canvas")

        els.forEach(canvas => {

            canvases.push({
                el: canvas,
                map: new Map(canvas, mapSettings),
                markers: [],
            })
        })

        return {
            Map,
            Marker,
        }
    }

    /**
     * Start the finder
     */
    async function init() {

        const finder = document.querySelector(".finder")
        if (!finder) return

        Google = await loadGoogleMaps()
        createMarkers()
        showMarkers()
        bindDomEvents()
        setupSearch()
    }


    init()
})
