
function init() {
    // document.querySelector('#video').showModal()
    const dialog = document.querySelector('#video')
    const trigger = document.querySelector('.video')

    if (!trigger || !dialog) return

    const iframe = dialog.querySelector('iframe')

    const baseURL = iframe.src

    const videoId = trigger.dataset.video

    if (!videoId) return

    dialog.addEventListener('click', e => {
        if(e.target.nodeName === 'DIALOG') {
            iframe.setAttribute('src', baseURL)
            dialog.close()
        }
    })

    dialog.querySelector('.video-dialog__close').addEventListener('click', () => {
        iframe.setAttribute('src', baseURL)
        dialog.close()
    })

    trigger.addEventListener('click', () => {
        iframe.setAttribute('src', baseURL + videoId)
        dialog.showModal()
    })

}

window.addEventListener('load', () => {
    init()
})
