
export default function parseLatLng(string) {

    if (string.indexOf(",") < 0) {
        throw new Exception("Not a valid string.")
    }

    const [lat, lng] = string.split(",")
    return {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
    }
}

