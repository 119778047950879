

/** 
  * Get the path for icons by using the main.css file 
  * If the main.css file doesn't exist we will return an empty string and icons will have no path
  */
export default function getIconPath() {
    let iconpath = ''

    const links = Array.from(document.querySelectorAll('link'))
    const link = links.find(s => (s.getAttribute('href').indexOf('main.css') > -1))

    // Kind of gross we have to select the href twice
    let path = link.getAttribute('href').split('/')
    path.pop()
    path.pop()
    path.push('icons/')
    iconpath = path.join('/')

    return iconpath
}
