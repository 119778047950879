
import initMaps from "../vendor/google-maps.js"
import getIconPath from "../helpers/getIconPath.js";
import parseLatLng from "../helpers/parseLatLng.js";

window.addEventListener('load', function () {

    const finder = document.querySelector(".finder-showroom")
    const canvas = document.querySelector('.map-canvas')

    if (!canvas || !finder) {
        console.log("Google maps can't work without a canvas")
        return
    }

    const iconpath = getIconPath()
    const center = parseLatLng(canvas.dataset.latlng)

    let map = null
    let Google = null
    /**
     * Load Google maps and expose APIs
     * We create one map per canvas and store them in an array maps for later
     * One map is used on mobile and one is used on desktop
     */
    async function loadGoogleMaps() {

        initMaps({
            key: "AIzaSyDbwP2WUsq-wEizQLcAtkgS6n_dGqKtxB8", // This should come from and .env file :(
            v: "weekly",
        })

        const { Map } = await google.maps.importLibrary("maps")
        const { Marker } = await google.maps.importLibrary("marker")

        map = new Map(canvas, {
            zoom: 9,
            center,
            mapTypeControl: false,
        })

        return {
            Map,
            Marker,
        }
    }

    /**
     * Start the finder
     */
    async function init() {

        Google = await loadGoogleMaps()

        const type = canvas.dataset.type
        const scaledSize = new google.maps.Size(60, 60)

        const marker = new Google.Marker({
            map,
            position: center,
            type: "o",
            icon: {
                url: `${iconpath}pin-${type}.svg`,
                scaledSize,
            },
        })

        marker.setMap(map)

    }

    init()
})
