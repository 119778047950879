
function hide(e) {
    e.target.classList.remove('hide')
    e.target.close()
    e.target.removeEventListener('webkitAnimationEnd', hide)
}

function setup(dialog) {

    const { id } = dialog
    let timer = parseInt(dialog.dataset.timer)

    if(!timer) timer = 0

    // @TODO Set cookie
    // setTimeout(() => {
    //     dialog.show()
    // }, timer)

    // Clicking close button in dialog
    dialog.querySelector('.subscribe-dialog__close').addEventListener('click', () => {
        dialog.classList.add('hide')
        dialog.addEventListener('webkitAnimationEnd', function() {
            dialog.classList.remove('hide')
            dialog.close()
            dialog.removeEventListener('webkitAnimationEnd', arguments.callee, false)
        }, false)
    })
}

function init() {

    const dialogs = Array.from(document.querySelectorAll(".subscribe-dialog"))

    if (!dialogs.length) return

    dialogs.forEach(setup)
}

init()
