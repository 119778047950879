import { tns } from "tiny-slider"

function resizeLabels(index, height) {
    const labels = Array.from(document.querySelectorAll('.compare-label'))

    labels[index].style.height = `${(height).toFixed(2)}px`
}

function goToNext(slider) {
    slider.goTo('next')
}

function goToPrev(slider) {
    slider.goTo('prev')
}

function setup (containers) {

    const controlsContainer = document.querySelector('.compare-controls')
    const next = controlsContainer.querySelector('.next')
    const prev = controlsContainer.querySelector('.prev')

    const sliders = containers.map((container, index) => {

        const slider = tns({
            container,
            controls: false,
            // controlsContainer,
            mouseDrag: false,
            nav: false,
            items: 1,
            mode: "gallery",
            responsive: {
                480: {
                    items: 2
                },
                768: {
                    items: 3
                },
                1024: {
                    items: 4
                }
            },
            onInit: function(slider) {
                resizeLabels(index, slider.container.getBoundingClientRect().height)
            }
        })
        return slider;
    })

    next.addEventListener('click', () => {
        sliders.forEach(goToNext)
    })

    prev.addEventListener('click', () => {
        sliders.forEach(goToPrev)
    })

    window.addEventListener('resize', () => {
        if (window.innerWidth >= 1480) {
            containers.forEach((container, index) => {
                resizeLabels(index, container.getBoundingClientRect().height)
            })
        }
    })
}

function init () {
    let containers = Array.from(document.querySelectorAll('.compare-list__product-line'))

    if (!containers.length) return

    // There is some dead markup being sent to the page
    // we handle that before doing anything else
    containers.forEach(container => {
        if (container.children.length < 1) {
            container.remove()
            return
        }
    })

    containers = Array.from(document.querySelectorAll('.compare-list__product-line'))

    setup(containers)
}


window.addEventListener('load', function () {
    init();
})

